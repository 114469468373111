<template>
	<div class="cbox">
		<div class="headbar">
			<div style="float: right">


				<el-cascader ref="firstCascader" :options="TreeData" :props="defaultProps" :show-all-levels="true"
					v-model="searchForm.class_name" @change="getSelectType" clearable placeholder="请选择" filterable
					style="width: 250px;margin-right:10px" size="small">
				</el-cascader>

				<!-- <el-select size="small" v-model="searchForm.status" placeholder="保险购买" clearable>
					<el-option label="已购保险" :value="1"></el-option>
					<el-option label="未购保险" :value="0"></el-option>
				</el-select> -->



				<el-input placeholder="关键字" v-model="searchForm.keyword" size="small" class="input-with-select"
					style="width:150px;margin-left: 10px" clearable></el-input>


				<el-button icon="el-icon-search" @click="getDataList" style="margin-left:10px" size="small"
					type="primary">搜索</el-button>
				<el-button v-if="TargetPmid" icon="el-icon-refresh" style="margin-left:10px" size="small"
					@click="tongbuData">同步数据</el-button>

				<el-button icon="el-icon-upload" style="margin-left:10px" size="small"
					@click="dialogshow1 = true">导入实习生名册</el-button>

					<el-button icon="el-icon-upload"  style="margin-left:10px" size="small"
					@click="dialogshow2 = true">导入实习审批表</el-button>
				
					<!-- <el-button icon="el-icon-download" title="请先选择班级" disabled style="margin-left:10px" size="small"
					@click="dialogshow1 = true">导出实习生名册</el-button>

					<el-button icon="el-icon-download" title="请先选择班级" disabled style="margin-left:10px" size="small"
					@click="dialogshow2 = true">导出实习审批表</el-button> -->





			</div>
			<div style="padding-top: 14px">
				<div style=" width: 200px;  margin-top:-10px;">
					<div style="width:40px;height:40px;background-color: #ebf5ff; border-radius: 50%;float: left;">
						<img :src="'/img/page.png'" style="width:60%;height:60%;margin-left: 20%;margin-top:20%" />
					</div>
					<div style="margin-left: 50px; color:#535353; font-size:20px !important">实习列表</div>
				</div>

			</div>
		</div>
		<div class="bcontent">


			<div>
				<el-table :data="tableData" row-key="id" border stripe>
					<el-table-column type="index" label="序号" width="55px" align="center"></el-table-column>
					<!-- <el-table-column label="学校" prop="platform" min-width="150" align="center"></el-table-column> -->
					<el-table-column label="所属系部" prop="xibu_name" width="150" align="center"></el-table-column>
					<el-table-column label="所属班级" prop="class_name" width="150" align="center"></el-table-column>
					<el-table-column label="学生姓名" prop="stu_name" min-width="150" align="center"></el-table-column>
					<!-- <el-table-column label="学号" prop="stu_code" width="150" align="center"></el-table-column> -->
					<el-table-column label="企业名称" prop="enter_name" min-width="150" align="center"></el-table-column>
					<el-table-column label="岗位名称" prop="job" width="150" align="center"></el-table-column>
					<el-table-column label="实习薪资" prop="salary" width="150" align="center"></el-table-column>
					<el-table-column label="企业电话" prop="enter_phone" min-width="150" align="center"></el-table-column>

					<el-table-column fixed="right" label="操作" width="120">
						<template slot-scope="scope">
							
							<el-button @click="viewInfo(scope.row)" type="text" size="small">详情</el-button>
							<el-button @click="deleteRow(scope.row)" type="text" size="small">删除</el-button>
						</template>
					</el-table-column>
				</el-table>

				<div style="text-align:right;padding-top: 10px;">
					<el-pagination background @current-change="handlePageChange" :current-page="page.current_page"
						:page-size="page.per_page" :total="page.count" layout="total, prev, pager, next"></el-pagination>
				</div>


			</div>

		</div>
		<!-- <shixiinfo v-if="dialogShow" :shixi="shixi"></shixiinfo> -->


		<el-dialog class="cus_dialog " title="实习生名册导入" :visible.sync="dialogshow1" :close-on-click-modal="false"
			width="600px" height="30%">
			<div style="line-height: 50px;">
				<a href="/xls/毕业班实习生名册模板.docx" target="_blank">毕业班实习生名册模板下载（word/docx）</a>
			</div>

			<div style="line-height: 50px;color:cornflowerblue">
				如需批量导入实习模板文件，请将所有文件打包成zip压缩文件后直接上传即可
			</div>

			<div style="line-height: 50px; color:cornflowerblue">
				如果未安装压缩软件，点击这里下载安装：<a href="https://yasuo.360.cn/" target="_blank">360压缩</a>
			</div>


			
			<div style="text-align:right">
				<el-upload style="display: inline-block; margin-right: 0px;" action="" :http-request="uploadTemp"
					:on-success="uploadFiles" :show-file-list="false" name="image">
					<el-button icon="el-icon-upload" type="primary" style="margin-left:100px"
						size="small">导入毕业班实习生名册</el-button>
				</el-upload>
			</div>

		</el-dialog>


		<el-dialog class="cus_dialog" title="毕业实习审批表" :visible.sync="dialogshow2" :close-on-click-modal="false"
			width="600px" height="30%">

			<div style="line-height: 50px;">
				<a href="/xls/毕业实习审批表模板.docx" target="_blank">毕业实习审批表导入模板下载（word/docx）</a>
			</div>
			<div style="line-height: 50px;color:cornflowerblue">
				如需批量导入实习模板文件，请将所有文件打包成zip压缩文件后直接上传即可
			</div>

			<div style="line-height: 50px; color:cornflowerblue">
				如果未安装压缩软件，点击这里下载安装：<a href="https://yasuo.360.cn/" target="_blank">360压缩</a>
			</div>

			<div style="text-align:right">
				<el-upload style="display: inline-block; margin-right: 20px;" action="" :http-request="uploadTemp"
					:on-success="uploadFiles2" :show-file-list="false" name="image">
					<el-button icon="el-icon-upload" type="primary" style="margin-left:100px"
						size="small">导入毕业实习审批表</el-button>
				</el-upload>
			</div>
		</el-dialog>


		<sxstuinfo v-if="infoShow" :stu_id="sxId"></sxstuinfo>



	</div>
</template>

<script>
import sxstuinfo from './com/sxstuinfo.vue'

export default {
	components: {
		sxstuinfo
	},
	data() {
		return {
			dialogShow: false,
			dialogshow1: false,
			dialogshow2: false,
			infoShow:false,
			searchForm: {
				class_name: "",
				keyword: "",
				status: ""

			},
			TargetPmid: 0,
			tableData: [],
			TreeData: [],
			page: {
				count: 0,
				current_page: 1,
				per_page: 20,
				total_page: 0
			},
			defaultProps: {
				label: 'name',
				value: 'name',
				children: 'children',
				emitPath: false,
				checkStrictly: true,

			},
			shixi: null,
			sxId:0
		}
	},
	mounted() {
		this.getTargetPmid()
		this.getDataList()
		this.getTree()

	},
	methods: {
		getTargetPmid() {
			this.$http.post("/api/sch_qsx_target").then(res => {
				if (res.data && res.data.qsx_pmid) {
					this.TargetPmid = res.data.qsx_pmid
				}
			})

		},
		getTree() {
			this.$http.post("/api/shixi_grade_tree").then(res => {
				this.TreeData = res.data
			})
		},
		getSelectType(e) {
			let node = this.$refs['firstCascader'].getCheckedNodes()
			if (node[0]) {
				let path = node[0].pathNodes[0].label
				if (node[0].pathNodes.length >= 2) {
					this.searchForm.xibu_name = node[0].pathNodes[0].label
					this.searchForm.class_name = node[0].pathNodes[1].label
				} else if (node[0].pathNodes.length >= 1) {
					this.searchForm.xibu_name = node[0].pathNodes[0].label
					this.searchForm.class_name = ""
				} else {
					this.searchForm.xibu_name = ""
					this.searchForm.class_name = ""
				}
			} else {
				this.searchForm.xibu_name = ""
				this.searchForm.class_name = ""

			}

		},
		tongbuData() {
			this.$http.post("/api/tongbu_shixi", { loading: true, tpmid: this.TargetPmid }).then(res => {
				this.$message.success("同步成功")
				this.page.current_page = 1;
				this.getDataList();
			})
		},
		getDataList() {
			let data = {
				page: this.page.current_page,
				keyword: this.searchForm.keyword,
				xibu_name: this.searchForm.xibu_name,
				class_name: this.searchForm.class_name,
				status: this.searchForm.status,
				showloading: true
			}
			this.$http.post("/api/shixi_list", data).then(res => {
				this.tableData = res.data.data
				this.page = res.data.page
			})


		},
		handlePageChange(page) {
			this.page.current_page = page;
			this.getDataList();
		},
		viewInfo(e) {
			// e.keyword = this.searchForm.keyword
			// e.xibu_name = this.searchForm.xibu_name
			// e.class_name = this.searchForm.class_name
			this.sxId = e.id

			this.infoShow = true
		},
		uploadFiles(e) {
			if (e.src.indexOf("docx") > 0||e.src.indexOf(".zip") > 0) {
				let url = ""
				if(e.src.indexOf("docx") > 0){
					url ="/api/import_bybsxsmc_word"
				}else if(e.src.indexOf(".zip") > 0){
					url = "/api/import_bybsxsmc_zip"
				}
				this.$http.post(url, {
					url: e.src
				}).then(res => {
					if (res.data.msg && res.data.msg.length > 0) {
						this.$message.success(res.data.msg )
					}else{
						this.$message.success("导入成功")
					}
					this.dialogshow1 = false
					this.getDataList();

				})

			} else {
				this.$message.error("请上传docx格式文件")
			}


			// this.$http.post("/api/import_shixixls", {
			// 	url: e.src
			// }).then(res => {

			// 	this.getDataList()
			// 	this.$message.success("导入成功")
			// 	if (res.data.msg.length > 0) {
			// 		let html = ''
			// 		for (let msg of res.data.msg) {
			// 			html += "<div style='font-size:12px'>" + msg.stu_name + ":" + msg.msg + "</div>"
			// 		}
			// 		this.$alert(html, '未导入数据', {
			// 			dangerouslyUseHTMLString: true
			// 		});
			// 	}
			// 	this.dialogshow1 = false


			// })
		},
		uploadFiles2(e) {
			if (e.src.indexOf("docx") > 0||e.src.indexOf(".zip") > 0) {
				let url = ""
				if(e.src.indexOf("docx") > 0){
					url ="/api/import_byspb_word"
				}else if(e.src.indexOf(".zip") > 0){
					url = "/api/import_byspb_zip"
				}
				this.$http.post(url, {
					url: e.src
				}).then(res => {
					this.$message.success("导入成功")
					this.dialogshow2 = false
					this.activeName=""
					this.$nextTick(e=>{
						this.activeName="second"
					})
				
					
				})

			} else {
				this.$message.error("请上传docx格式文件")
			}
		},
		
		deleteRow(e) {

			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post("/api/shixi_delete", { id: e.id }).then(res => {
					this.getDataList()
					this.$message({
						type: 'success',
						message: '删除成功!'
					});

				})
			}).catch(() => {

			});




		}

	}
}
</script>
<style scoped></style>
